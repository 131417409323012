import React, { useCallback, useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { SeriesClickEventObject } from 'highcharts';
import { isArray, isEmpty, isNil } from 'lodash';
import { Typography } from '@material-ui/core';
import { generateLineGraphConfig } from 'src/components/Visualize/Visualize.utils';
import {
  visualizeButtonContainer,
  getVisualizeChartItem,
  metricLineGraphContainer,
  lineGraphContainer,
} from 'src/components/Visualize/Visualize.styles';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { Overlay } from 'src/common-ui';
import service from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import Highcharts from 'highcharts';
import coalesce from 'src/utils/Functions/Coalesce';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export const viewDefnToButton = (
  clickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  currentSelectedIndex: number,
  viewItem: TenantConfigViewItem,
  index: number
): JSX.Element => {
  return (
    <span
      className={getVisualizeChartItem(index === currentSelectedIndex)}
      key={index}
      id={viewItem.text}
      data-index={index}
      onClick={clickHandler}
    >
      {viewItem.text}
    </span>
  );
};

interface MetricLineGraphProps {
  defnId: string;
  selectionRequired: boolean;
  selectedId: string;
  height: number;
  timeDataIndex?: string;
  onItemClick?: (data: unknown) => void;
}

const MetricLineGraph = ({
  defnId,
  selectionRequired,
  selectedId,
  height,
  timeDataIndex = 'week',
  onItemClick,
}: MetricLineGraphProps) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [viewDefn, setViewDefn] = useState<TenantConfigViewData | undefined>(undefined);
  // FIXME: needs type fixed instead of unknown
  const [data, setData] = useState<unknown[][][] | undefined>(undefined);
  const [categories, setCategories] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    async function fetchConfigThenData() {
      setCategories(undefined);
      setData(undefined);

      const configResp = await service.tenantConfigClient.getTenantViewDefn<TenantConfigViewData>({
        defnId,
        appName: ASSORTMENT,
      });
      const xAxisDataIndex = coalesce(configResp.graphs?.dataIndex, timeDataIndex, 'week');

      const dataApi = (configResp as any).dataApi;
      const dataResp = await service.pivotService.listData(dataApi.defnId, ASSORTMENT, {
        ...dataApi.params,
        topMembers: selectedId,
      });

      const categories = dataResp.tree.map((x) => x[xAxisDataIndex!]);
      const data = configResp.graphs?.primary.map((config) =>
        ['left', 'right'].map((key) => {
          const graphConfig = config[key];
          if (!isArray(graphConfig)) {
            return dataResp.tree
              .slice(0, categories.length)
              .map((dataItem) => ({ ...dataItem, y: dataItem[graphConfig.dataIndex] }));
          }
          return graphConfig.map((graph) => {
            return dataResp.tree
              .slice(0, categories.length)
              .map((dataItem) => ({ ...dataItem, y: dataItem[graph.dataIndex] }));
          });
        })
      );

      setViewDefn(configResp);
      setCategories(categories);
      setData(data);
    }

    if (selectionRequired && isEmpty(selectedId)) {
      return;
    }
    fetchConfigThenData();
  }, [defnId, selectionRequired, selectedId, timeDataIndex]);

  const onLineGraphItemClick = useCallback(
    (event: SeriesClickEventObject) => {
      if (isNil(onItemClick)) {
        return;
      }
      const item = event.point.options;
      onItemClick(item);
    },
    [onItemClick]
  );

  const onLineGraphButtonClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (event.currentTarget.dataset.index) {
      setSelectedButtonIndex(parseFloat(event.currentTarget.dataset.index));
    }
  }, []);

  const viewDefnToButtonApplied = viewDefnToButton.bind(null, onLineGraphButtonClick, selectedButtonIndex);
  const loading = isNil(viewDefn) || isNil(data) || isNil(categories);

  if (selectionRequired && isEmpty(selectedId)) {
    // should not hit this
    return (
      <Typography variant="subtitle2" color="primary">
        Select an item to show line graph
      </Typography>
    );
  } else if (loading) {
    return (
      <section style={{ minHeight: `${height}px` }} className={metricLineGraphContainer}>
        <Overlay type="loading" visible={true} fitParent={true} />
      </section>
    );
  }

  const lineGraphConfig = generateLineGraphConfig(
    categories,
    data as BasicPivotItem[][][],
    viewDefn,
    selectedButtonIndex,
    height,
    onLineGraphItemClick
  );

  return (
    <section className={metricLineGraphContainer}>
      <div className={visualizeButtonContainer}>{viewDefn.graphs?.primary.map(viewDefnToButtonApplied)}</div>
      <HighchartsReact
        highcharts={Highcharts}
        options={lineGraphConfig}
        containerProps={{ className: lineGraphContainer }}
        immutable={true}
      />
    </section>
  );
};
export default MetricLineGraph;
