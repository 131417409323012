import { z } from 'zod';
import {
  BasicViewItem,
  NestedColumnsBasicViewItem,
  NestedOptionsBasicViewItem,
  NestedViewBasicViewItem,
  zCompanionView,
  ConfigRefs,
  ConfigurableGridActions,
  zConfigurableGridGraph,
  MainView,
  MassEditConfig,
  SalesAdjustment,
  SearchIndexes,
  SubheaderDropdown,
  WorklistTab,
  ParetoSection,
  StyleEditHeader,
  StyleEditSection,
  TimeLevels,
  MainViewDefnConfig,
  GridMainViewDefn,
  CompanionViewDefn,
  zConfigurablePostAction,
  zTopAttribute,
  ConfigureViewDefn,
  zTransposedColumns,
  zMassEditCoordinateMap,
  zNoRowsOverlay,
  zCompanionCard,
} from 'viewdefns/general';
import { zAdornments } from 'viewdefns/literals';
import { o_IdentifierProps, zClientDataApi, zDataApi } from '../confdefnView';

// Adding to this validator type will allow certain features to be configured for all views.
// This just means the component will support adornments, it will still need to be configured to render in the card/grid items
export const o_HasAdornments = { adornments: z.array(zAdornments).default([]) };
export const zHasAdornments = z.object(o_HasAdornments);
const o_hasCardWidth = {
  numberCardsWide: z.number().optional().default(2),
};

const zConfigurableGridTopAttributes = z
  .object({
    title: z.string().optional(),
    expanded: z.boolean().optional(),
    left: z.array(zTopAttribute),
    right: z.array(zTopAttribute),
  })
  .optional();

export const zSubheaderDropdowns = z.object({
  groupBy: SubheaderDropdown.optional(),
  topMember: z
    .object({
      label: z.string(),
      dataApi: zClientDataApi,
    })
    .optional(),
});
export type SubheaderDropdowns = z.infer<typeof zSubheaderDropdowns>;

export const ConfigurableGridViewDefn = z.object({
  ...o_HasAdornments,
  title: z.string(),
  // TODO: can this shape be converted to match zCompanionView
  // TODO: update name in viewdefn to companionList,
  // it's configured as CompanionGrid but really represents the companion list's config
  companionGrid: z.object({
    main: zCompanionCard.optional(),
    label: z.string(),
    sortConfig: z.object({
      defaults: z.string().optional(),
      view: z.array(BasicViewItem),
    }),
  }),
  subheaderDropdowns: zSubheaderDropdowns,
  columns: z.array(BasicViewItem),
  main: GridMainViewDefn.optional(),
  actions: ConfigurableGridActions.optional(),
  $configRefs: ConfigRefs.optional(),
  salesAdjustment: SalesAdjustment,
  showPublish: z.boolean().optional(),
  hideUnpublish: z.boolean().optional(),
  publishText: z.string().optional(),
  unpublishText: z.string().optional(),
  publishAttribute: z.string().optional(),
  massEditConfig: MassEditConfig.optional(),
  updateCoordinateMap: zMassEditCoordinateMap.optional(),
  onlySendCoordinateMap: z.boolean().optional(),
  topAttributes: zConfigurableGridTopAttributes,
  configurablePostAction: zConfigurablePostAction.optional(),
  redecorateMap: z.record(z.string()).optional(),
  configure: ConfigureViewDefn.optional(),
  graph: zConfigurableGridGraph.optional(),
  searchIndexes: z.array(z.string()).optional(),
  noRowsOverlay: zNoRowsOverlay.optional(),
});

export interface ConfigurableGridViewDefn extends z.infer<typeof ConfigurableGridViewDefn> {}

export const zWorklistViewDefn = z.object({
  ...o_HasAdornments,
  title: z.string(),
  defaultTab: z.string(),
  tabs: z.array(WorklistTab),
  companionView: zCompanionView,
  subheaderDropdowns: zSubheaderDropdowns.optional(),
});

export interface WorklistViewDefn extends z.infer<typeof zWorklistViewDefn> {}

export const NestedGridViewDefn = z.object({
  ...o_HasAdornments,
  id: z.string(),
  type: z.literal('grid'),
  main: GridMainViewDefn,
  // This is a legacy property used for AA Receipt Grid, but now we used the 0th entry of the componentProps.defns.view array as this value
  // This functionality should always pull from componentProps.defns.model and the model property in the viewDefn should be removed later
  // INT-2616 tracks the need for this migration
  model: z.string().optional(),
  view: z.array(NestedColumnsBasicViewItem.or(BasicViewItem)),
});

export const ParetoSummaryViewDefn = z.object({
  ...o_HasAdornments,
  id: z.string(),
  view: z.array(ParetoSection),
});
export const StoreGroupViewDefn = z.object({
  ...o_HasAdornments,
  view: z.array(
    z.object({
      text: z.string(),
      view: z.array(BasicViewItem),
    })
  ),
  main: z.object({
    image: z.string(),
    title: z.string(),
    ...o_IdentifierProps,
  }),
});

export const ProductMixViewDefn = z.object({
  ...o_HasAdornments,
  id: z.string(),
  defaults: z.string(),
  view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
});

export const ProductivityViewDefn = z.object({
  ...o_HasAdornments,
  view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
});

export const StyleEditViewDefn = z.object({
  ...o_HasAdornments,
  title: z.string(),
  searchIndexes: SearchIndexes,
  sectionsViewDefns: z.array(z.string()),
  companionView: zCompanionView,
});

export const StyleEditSectionsViewDefn = z.object({
  ...o_HasAdornments,
  header: StyleEditHeader,
  sections: z.array(StyleEditSection).optional().default([]),
});

export const NestedOvertimeViewDefn = z.object({
  ...o_HasAdornments,
  title: z.string(),
  main: GridMainViewDefn.optional(),
  columns: z.array(BasicViewItem),
  // TODO: needs to match the other configure view defn shape 'ConfigureViewDefn' in general.ts
  configure: z.object({
    title: z.string(),
    defaults: z.array(z.string()),
    view: z.array(NestedOptionsBasicViewItem),
    transposedColumns: zTransposedColumns,
  }),
  timeLevels: TimeLevels.optional(),
});

export const NestedStyleOvertimeViewDefn = z.object({
  ...o_HasAdornments,
  title: z.string(),
  main: GridMainViewDefn,
  columns: z.array(BasicViewItem),
  companionGrid: z.object({
    label: z.string(),
    sortConfig: z.object({
      defaultSelection: z.string(),
      options: z.array(BasicViewItem),
    }),
  }),
  timeLevels: TimeLevels.optional(),
});

export const CanvasViewDefn = z.object({
  ...o_HasAdornments,
  main: MainViewDefnConfig,
  model: z.string().optional(),
  view: z.array(BasicViewItem),
  text: z.string().optional(),
  assortmentModel: z.string().optional(),
});

export const SummaryViewDefn = z.object({
  ...o_HasAdornments,
  main: MainViewDefnConfig,
  view: z.array(BasicViewItem),
});

export const GridViewDefn = z.object({
  ...o_HasAdornments,
  type: z.literal('grid'),
  main: GridMainViewDefn,
  view: z.array(BasicViewItem.or(NestedColumnsBasicViewItem)),
  searchIndexes: z.array(z.string()).optional(),
});

export const FlowTypeViewDefn = z.object({
  ...o_HasAdornments,
  searchIndexes: z.array(z.string()).optional(),
  main: MainViewDefnConfig,
  view: z.array(BasicViewItem),
});

export const TopTYvsLYViewDefn = z.object({
  ...o_HasAdornments,
  searchIndexes: z.array(z.string()).optional(),
  main: MainViewDefnConfig,
  view: z.array(BasicViewItem),
});

export const CollectionViewDefn = z.object({
  ...o_HasAdornments,
  type: z.literal('rollUp'),
  searchIndexes: z.array(z.string()).optional(),
  view: z.array(BasicViewItem),
});

export const FloorsetComparisonViewDefn = z.object({
  ...o_HasAdornments,
  main: MainView,
  view: z.array(BasicViewItem),
});

export const zFlowSheetGridDefn = z.object({
  ...o_HasAdornments,
  inputType: z.literal('grid'),
  model: z.string(),
  main: GridMainViewDefn,
  view: z.array(BasicViewItem),
  timeLevels: TimeLevels.optional(),
});

export interface FlowSheetGridDefn extends z.infer<typeof zFlowSheetGridDefn> {}

// New Mass Edit options
const OptionSchema = z.object({
  value: z.union([z.string(), z.number()]),
  label: z.string(),
});
const zExtraModifierSchema = z.object({
  text: z.string(),
  dataIndex: z.string(),
  editor: z.enum(['radiogroup', 'checkboxgroup']), // Available editor types
  options: z.array(OptionSchema), // Options required for radio/checkbox fields
  defaultValue: z.string().or(z.number()).optional(),
});

export interface ExtraModifier extends z.infer<typeof zExtraModifierSchema> {}

const zModifierTypes = BasicViewItem.merge(
  z.object({
    editor: z.string().optional(), // to allow blank objects that used to be needed to pass through, will be ignored later
    dataApi: zDataApi.optional(),
    valueType: z.string().optional(),
    renderer: z.string().optional(),
    extraModifiers: z.array(zExtraModifierSchema).optional(),
  })
);

const MassEditViewDefn = z.union([
  z.object({
    title: z.string(),
    modifierTypes: z.array(zModifierTypes).optional(),
    dataIndex: z.string().optional(),
  }),
  z.object({
    dataIndex: z.string(),
    editor: z.string(),
    title: z.string(),
  }),
]);

export const MassEditDefn = z.object({
  ...o_HasAdornments,
  hideEmpty: z.boolean().optional(),
  views: z.array(MassEditViewDefn),
});

// this PricingOverTime and GridViewDefn are exactly same shape, but only 1 minor differrent view and views.
export const PricingOverTimeDefn = z.object({
  ...o_HasAdornments,
  type: z.literal('grid'),
  main: GridMainViewDefn,
  views: z.array(BasicViewItem),
  searchIndexes: z.array(z.string()).optional(),
});

const o_ChartTooltipConfig = {
  dataIndex: z.string(),
  renderer: z.string(),
  text: z.string(),
};
const zChartTooltipConfig = z.object(o_ChartTooltipConfig);

export const zMacroMixDropdowns = z.object({
  xtype: z.literal('heatmap-selections'),
  text: z.string().default('Select Metric:'),
  type: z.literal('dropdown'),
  view: z.array(
    z.object({
      text: z.string(),
      modelId: z.string(),
      view: z.array(
        z.object({
          dataIndex: z.string(),
          dimension: z.string(),
        })
      ),
    })
  ),
});
export const zMacroMixHeatMap = z.object({
  xtype: z.literal('heatmap-conf'),
  dataIndex: z.string(),
  view: z.array(
    z.object({
      xtype: z.union([z.literal('display'), z.literal('trend')]),
      dataIndex: z.string(),
    })
  ),
});

export const o_MacroMixLineGraph = {
  text: z.string().default('Select Metric:'),
  view: z.array(
    z.object({
      ...o_ChartTooltipConfig,
      /** New property added in INT-4498 to support different x-axis category labels in the chart side charts in MacroMix */
      title: z.string().optional(),
      sortIndex: z.string().optional(), // Added in INT-4506
      view: z.array(
        z.object({
          dataIndex: z.string(),
          text: z.string(),
          bubble: zChartTooltipConfig,
        })
      ),
    })
  ),
};
export const zMacroMixLineGraph = z.object(o_MacroMixLineGraph);
// these are split because the discriminated union doesn't support spliting on union, such as 'left'.or('right')
export const zMacroMixLineGraphLeft = z.object({
  ...o_MacroMixLineGraph,
  xtype: z.literal('left-chart'),
});
export const zMacroMixLineGraphRight = z.object({
  ...o_MacroMixLineGraph,
  xtype: z.literal('right-chart'),
});

export const zMacroMixHeatMapToolTip = z.object({
  xtype: z.literal('description'),
  renderer: z.string(),
  mask: z.string(),
  dataIndex: z.string(),
});
export const zMacroMixViewDefn = z.object({
  type: z.literal('screen'),
  view: z.array(
    z.discriminatedUnion('xtype', [
      zMacroMixDropdowns,
      zMacroMixHeatMap,
      zMacroMixLineGraphLeft,
      zMacroMixLineGraphRight,
      zMacroMixHeatMapToolTip,
    ])
  ),
});
export interface MacroMixHeatMap extends z.infer<typeof zMacroMixHeatMap> {}
export interface MacroMixDropdowns extends z.infer<typeof zMacroMixDropdowns> {}
export interface MacroMixLineGraph extends z.infer<typeof zMacroMixLineGraphLeft> {}
export type SimplerChartConfig = MacroMixLineGraph['view'][0];
export interface MacroMixHeatMapToolTip extends z.infer<typeof zMacroMixHeatMapToolTip> {}
export interface MacroMixViewDefn extends z.infer<typeof zMacroMixViewDefn> {}

export const SizeEligibilityListGridDefn = z.object({
  ...o_HasAdornments,
  id: z.string(),
  type: z.string(),
  main: z.object({
    title: z.string(),
  }),
  companionApi: CompanionViewDefn,
  update: z.object({
    type: z.string(),
    keys: z.object({
      product: z.string(),
      cluster: z.string(),
    }),
  }),
  columns: z.array(BasicViewItem),
});
// This type below for ParameterToggle, but I think it's already validated with the type,
// So I still keep it here in case we need to use it. At the end, if we don't need to use this,
// then I will delete it later
// const ParameterTogglesFields = z.object({
//   text: z.string(),
//   editor: z.string(),
//   radioFields: z.array(BasicViewItem),
// });

// const ParameterTogglesSections = z.object({
//   title: z.string(),
//   icon: z.string(),
//   fields: z.array(ParameterTogglesFields),
// });

// export const ParameterTogglesDefn = z.object({
//   title: z.string(),
//   sections: z.array(ParameterTogglesSections),
//   allocPlanModel: z.string(),
// });

export const PublishAllocationGridDefn = z.object({
  ...o_HasAdornments,
  dataApi: z.object({
    isListData: z.boolean(),
    defnId: z.string(),
    params: z.object({
      aggBy: z.string(),
    }),
  }),
  main: GridMainViewDefn,
  title: z.string(),
  view: z.array(BasicViewItem),
});

export interface zChartTooltipConfig extends z.infer<typeof zChartTooltipConfig> {}
export const zGeoTrendsMapDefn = z.object({
  ...o_HasAdornments,
  defaultAggBy: z.string(),
  default: z.string(),
  mapUri: z.string(),
  title: z.string().optional(),
  bubble: zChartTooltipConfig,
  map: BasicViewItem.merge(
    z.object({
      minSize: z.string().or(z.number()),
      maxSize: z.string().or(z.number()),
      type: z.string(),
      trendDataIndex: z.string(),
      zoomBy: z
        .object({
          howMuch: z.number().or(z.undefined()),
          coords: z
            .object({
              lat: z.number(),
              lon: z.number(),
            })
            .optional(),
        })
        .optional(),
    })
  ),
  view: z.array(
    BasicViewItem.merge(
      z.object({
        groupingKey: z.string(),
        geoLocationKeys: z.array(z.string()),
      })
    )
  ),
  trends: z.array(
    z.object({
      text: z.string(),
      color: z.string(),
      trendValueMap: z.string().or(z.number()),
    })
  ),
});
export interface GeoTrendsMapDefn extends z.infer<typeof zGeoTrendsMapDefn> {}

export const zGeoTrendsChartsDefn = z.object({
  ...o_HasAdornments,
  view: z.array(
    BasicViewItem.merge(
      z.object({
        sortIndex: z.string().optional(),
        view: z.array(
          BasicViewItem.merge(
            z.object({
              bubble: zChartTooltipConfig,
            })
          )
        ),
      })
    )
  ),
});
export interface GeoTrendsChartsDefn extends z.infer<typeof zGeoTrendsChartsDefn> {}

export const zTrendCardType = z.enum(['metric', 'prediction']);
export type TrendCardType = z.infer<typeof zTrendCardType>;

export const zVariancePillConfig = z.object({
  valueDataIndex: z.string(),
  varianceDataIndex: z.string().or(z.null()),
  text: z.string(),
  valueRenderer: z.string().optional().default('usMoneyAbbreviated'),
  varianceRenderer: z.string().optional().default('simplePercent'),
});
export interface VariancePillConfig extends z.infer<typeof zVariancePillConfig> {}

const zTrendSummaryCardConfig = z.object({
  trendCardType: zTrendCardType,
  title: z.string(),
  trendDataIndex: z.string(),
  trendRenderer: z.string(),
  trendSummary: z.string(),
  currentDataIndex: z.string(),
  currentRenderer: z.string(),
  pills: z.array(zVariancePillConfig),
});
export interface TrendSummaryCardConfig extends z.infer<typeof zTrendSummaryCardConfig> {}
const zRendererParamsBar = z
  .object({
    tooltip: z.object({
      header: z.string(),
      value: z.string(),
      target: z.string(),
      dataSuffix: z.string(),
      renderer: z.string(),
    }),
    bars: z.array(
      z.object({
        valueDataIndex: z.string(),
        targetDataIndex: z.string(),
      })
    ),
  })
  .optional();
export const zTrendDetailsGridColumns = z.array(
  z.object({
    dataIndex: z.string(),
    text: z.string(),
    renderer: z.string().optional(),
    xtype: z.string().optional(),
    width: z.number().optional(),
    rendererParams: z
      .object({
        pills: z.array(zVariancePillConfig).optional(),
        bar: zRendererParamsBar,
        stacks: z.array(z.object({ dataIndex: z.string() })).optional(),
        subRenderer: z.string().optional(),
      })
      .optional(),
  })
);
export interface TrendDetailsGridColumns extends z.infer<typeof zTrendDetailsGridColumns> {}

export const zTrendDetailsGridConfig = z.object({
  type: z.string().optional(),
  grid: z.object({
    main: GridMainViewDefn.optional(),
    columns: zTrendDetailsGridColumns,
  }),
});
export interface TrendDetailsGridConfig extends z.infer<typeof zTrendDetailsGridConfig> {}

export const zTrendSummaryConfig = z.object({
  type: z.literal('trendCards').optional().default('trendCards'),
  cards: z.array(zTrendSummaryCardConfig),
  ...o_hasCardWidth,
});
export interface TrendSummaryConfig extends z.infer<typeof zTrendSummaryConfig> {}

const zTrendBubblesConfig = z.object({
  type: z.literal('trendBubbles').optional().default('trendBubbles'),
  graph: z.object({
    dataIndex: z.string(),
    groupDataIndex: z.string(),
    nameDataIndex: z.string(),
  }),
  chartOptions: z.object({
    chart: z.object({
      type: z.literal('packedbubble'),
    }),
    // taken from geotrends to match the existing bubble config we already have,
    // otherwise this is a straight copy of the highcharts config
    bubble: zChartTooltipConfig,
    // `plotOptions` here is intended to be close to a 1:1 replica of the highcharts `packedbubble` chart type
    plotOptions: z.object({
      packedbubble: z.object({
        minSize: z.string().optional(),
        maxSize: z.string().optional(),
        zMin: z.number().optional(),
        zMax: z.number().optional(),
        layoutAlgorithm: z.object({
          gravitationalConstant: z.number().optional(),
          splitSeries: z.boolean().optional(),
          seriesInteraction: z.boolean().optional(),
          dragBetweenSeries: z.boolean().optional(),
          parentNodeLimit: z.boolean().optional(),
        }),
        dataLabels: z.object({
          enabled: z.boolean().optional().default(true),
          format: zChartTooltipConfig,
          filter: z.object({
            property: z.string().optional(),
            operator: z.string().optional(),
            value: z.number().optional(),
          }),
          style: z.object({
            color: z.string().optional(),
            textOutline: z.string().optional(),
            fontWeight: z.string().optional(),
          }),
        }),
      }),
    }),
  }),
  ...o_hasCardWidth,
});
export interface TrendBubblesConfig extends z.infer<typeof zTrendBubblesConfig> {}

export const zTrendConfig = z.union([zTrendSummaryConfig, zTrendBubblesConfig, zTrendDetailsGridConfig]);

export type TrendConfig = TrendSummaryConfig | TrendBubblesConfig | TrendDetailsGridConfig;
